import React from "react";
import { toast } from "react-toastify";
import blueSuccessIcon  from "assets/images/BusinessDashboard/BusinessCustomers/successIcon.svg"

const SuccessToaster = ({ summary, position, summaryPara }) => {
  const isTopRight = position == "top-right";

  return (
    <div
      className={`flex justify-start items-center ${
        isTopRight ? "flex gap-[0.5rem] bg-[#CCFBF1] pl-2 pr-12" : "bg-[#F0FDF4] pl-5 pr-10"
      } rounded-lg w-[400px]  h-[80px] relative shadow-lg`}  
    >
          {isTopRight  ? <img src={blueSuccessIcon} alt="" className="w-[2rem] h-[2rem]"/> : ""}
          <div className="text-start">
        <p
          className={`font-[500] ${
            isTopRight ? " text-[#115E59] text-[1.1rem] font-[500]" : "text-[#334155] text-[20px]"
          }  m-0`}
        >
          {summary ? summary : "Added Successfully"}
        </p>
        {isTopRight && (
          <p className="text-[#0F766E] text-[0.9rem] m-0">
            {summaryPara}
          </p>
        )}
      </div>  
      <button
        className="text-[#272727] absolute top-3 right-4 font-[700]"
        onClick={() => toast.dismiss()}
      >
        ✕
      </button>
    </div>
  );
};

export default SuccessToaster;
