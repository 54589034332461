import React, { useState, useEffect } from "react";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import {
  BUSINESS_CUSTOMERS_URL,
  BUSINESS_NEW_CUSTOMERS_URL,
} from "constants/routes";
import WhiteButton from "components/Button/WhiteButton";
import OrangeButton from "components/Button/OrangeButton";
import "./CustomersInput.css";
import InputField from "components/input/InputField";
import uploadFile from "assets/images/reports/uploadFile.svg";
import arrow from "assets/images/incomeTax/chevron-down.svg";
import Stepper from "./Stepper";
import DiscardChangesDialog from "./DiscardChangesDialog";
import closeIcon from "assets/images/reports/closebutton.png";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import usePostCustomers from "./data/usePostCustomers";
import useGetImportGstDetails from "./data/useGetImportGstDetails";

const NewCustomerRegistered = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [companyLogo, setCompanyLogo] = useState("");
  const [expandedIndex, setExpandedIndex] = useState({ 0: true });
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [fetchingData, setFetchingData] = useState(false);
  const [showStep1Content, setSowStep1Content] = useState(false);
  const [noGstNo, setNoGstNo] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [gstNo, setGstNo] = useState("");

  const [errors, setErrors] = useState({
    step1: {},
    step2: {},
  });
  const [addNewPerson, setAddNewPerson] = useState([
    {
      name: "",
      role: "",
      phone: "",
      email: "",
      panNo: "",
      panImg: "",
    },
  ]);

  const [getData, getError, getIsLoading, getImportGstDetails] =
    useGetImportGstDetails();
  const [postData, postError, postIsLoading, postCustomerDetails] =
    usePostCustomers();

  useEffect(() => {
    if (postData) {
      const companyName= postData?.data?.company_name;
      showSuccessToast("Successfully added.", "top-right", `You have successfully added ${companyName} to your customers.`);
      navigate(BUSINESS_CUSTOMERS_URL);
    }
  }, [postData]);

  useEffect(() => {
    if (postError) {
      showErrorToast("Error in Creating Customer");
    }
  }, [postError]);

  useEffect(() => {
    if (getData) {
      console.log("gstData",getData)
      const data = getData?.data?.customer_details;
      setCompanyName(data?.company_name ? data?.company_name : "");
      setIndustry(data?.Industry_type ? data?.Industry_type : "");
      setWebsite(data?.website ? data?.website : "");
      setPhone(data?.phone ? data?.phone : "");
      setEmail(data?.email ? data?.email : "");
      setAddress1(data?.address_registered ? data?.address_registered : "");
      setAddress2(data?.address_communication ? data?.address_communication : "");
    }
  }, [getData]);

  useEffect(() => {
    // Reset errors when any field is updated
    if (companyName !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          companyName: "",
        },
      }));
    }
    if (industry !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          industry: "",
        },
      }));
    }
    if (website !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          website: "",
        },
      }));
    }
    if (phone !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          phone: "",
        },
      }));
    }
    if (address1 !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          address1: "",
        },
      }));
    }
    if (address2 !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          address2: "",
        },
      }));
    }
    if (companyLogo !== "") {
      setErrors((prev) => ({
        ...prev,
        step1: {
          ...prev.step1,
          companyLogo: "",
        },
      }));
    }
  }, [companyName, industry, website, phone, address1, address2, companyLogo]);

  const handleAddNewPerson = () => {
    const newIndex = addNewPerson.length;
    setAddNewPerson([
      ...addNewPerson,
      {
        name: "",
        role: "",
        phone: "",
        email: "",
        panNo: "",
        panImg: "",
      },
    ]);
    setExpandedIndex({ [newIndex]: true });
  };

  const validateStep1 = () => {
    const newErrors = {};
    const websiteRegex =
      /^(https?:\/\/)([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    const phoneRegex = /^[0-9]{10,15}$/; // 10 to 15 digits allowed

    // if (!companyLogo) newErrors.companyLogo = "Company logo is required.";

    if (!companyName) {
      newErrors.companyName = "Company Name is required.";
    } 
    // else if (!/^[A-Za-z\s]+$/.test(companyName.trim())) {
    //   newErrors.companyName = "Company Name can only contain letters.";
    // }

    // if (!industry) newErrors.industry = "Industry is required.";

    // if (!website) {
    //   newErrors.website = "Website is required.";
    // } else if (!websiteRegex.test(website)) {
    //   newErrors.website =
    //     "Enter a valid website URL starting with http:// or https:// (e.g., https://example.com).";
    // }

    // if (!phone) {
    //   newErrors.phone = "Phone is required.";
    // } else if (!phoneRegex.test(phone)) {
    //   newErrors.phone = "Enter a valid phone number";
    // }

    // if (!address1) newErrors.address1 = "Address1 is required.";
    // if (!address2) newErrors.address2 = "Address2 is required.";
    return newErrors;
  };

  const validateStep2 = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10,15}$/; // 10 to 15 digits allowed

    addNewPerson.forEach((person, index) => {
      if (!person.name) {
        newErrors[`name_${index}`] = "Name is required.";
      } 
      // else if (!/^[A-Za-z\s]+$/.test(person.name.trim())) {
      //   newErrors[`name_${index}`] = "Name can only containe letters.";
      // }

      // if (!person.role) {
      //   newErrors[`role_${index}`] = "Role is required.";
      // } else if (!/^[A-Za-z\s]+$/.test(person.role.trim())) {
      //   newErrors[`role_${index}`] = "Role can only containe letters.";
      // }

      // if (!person.phone) {
      //   newErrors[`phone_${index}`] = "Phone is required.";
      // } else if (!phoneRegex.test(person.phone)) {
      //   newErrors[`phone_${index}`] = " Enter a valid phone number";
      // }

      // if (!person.email) {
      //   newErrors[`email_${index}`] = "Email is required.";
      // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(person.email)) {
      //   newErrors[`email_${index}`] = "Invalid email format.";
      // }
    });
    return newErrors;
  };

  const handleChange = (index, field, value) => {
    setAddNewPerson((prev) =>
      prev.map((person, i) =>
        i === index ? { ...person, [field]: value } : person
      )
    );

    // Clear the error for the field if it's corrected
    setErrors((prev) => {
      const step2Errors = { ...prev.step2 };
      delete step2Errors[`${field}_${index}`];
      return { ...prev, step2: step2Errors };
    });
  };

  const removePerson = (index) => {
    setAddNewPerson((prevPerson) => prevPerson.filter((_, i) => i !== index));
  };

  const toggleExpand = (index) => {
    setExpandedIndex({ [index]: true }); // Collapse others and expand the current one
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files; // Get dropped files
    handleDrop(files); // Call the handleDrop method
  };

  const handleDrop = (files) => {
    setCompanyLogo(files[0]);
  };

  const handleBackButton = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
    // navigate(BUSINESS_NEW_CUSTOMERS_URL.replace(":type", "select"));
  };

  const ImportGstDetails = () => {
    const formData = new FormData();
    formData.append("is_customer_gst_registered", "True");
    formData.append("gst_number", gstNo);
    // postImportGstDetails(formData, user?.business_id);
  };

  const CreateCustomer = () => {
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("is_customer_gst_registered", "True");
    formData.append("Industry_type", industry);
    formData.append("website", website);
    formData.append("phone", phone);
    if (email) {
      formData.append("email", email);
    }
    formData.append("address_registered", address1);
    formData.append("address_communication", address2);
    formData.append("company_logo", companyLogo);
    formData.append("gst_number", gstNo);

    addNewPerson.forEach((item, index) => {
      if(index === 0) formData.append(`contact_persons.${index}.is_primary`, "True");
      formData.append(`contact_persons.${index}.name`, item.name);
      formData.append(`contact_persons.${index}.role`, item.role);
      formData.append(`contact_persons.${index}.phone`, item.phone);
      formData.append(`contact_persons.${index}.email`, item.email);
      if (item.panNo)
        formData.append(`contact_persons.${index}.pan_card`, item.panNo);
    });
    postCustomerDetails(formData, user?.business_id);
  };

  const handleNextClicked = () => {
    if (currentStep === 1) {
      const step1Errors = validateStep1();
      if (Object.keys(step1Errors).length > 0) {
        setErrors((prev) => ({ ...prev, step1: step1Errors }));
        return;
      }
    }

    if (currentStep === 2) {
      const step2Errors = validateStep2();
      if (Object.keys(step2Errors).length > 0) {
        setErrors((prev) => ({ ...prev, step2: step2Errors }));
        return;
      }
      // showSuccessToast("All fields are filled!");
      CreateCustomer();
    }

    setCurrentStep((prev) => Math.min(prev + 1, steps.length));
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const DiscardChanges = () => {
    setCompanyName("");
    setIndustry("");
    setWebsite("");
    setPhone("");
    setEmail("");
    setAddress1("");
    setAddress2("");
    setOpenDialog(false);
    setCompanyLogo("");
    setAddNewPerson([
      {
        name: "",
        role: "",
        phone: "",
        email: "",
        panNo: "",
        panImg: "",
      },
    ]);
    setErrors({ step1: {}, step2: {} });
  };

  const handleImportClicked = () => {
    // Example GST validation regex
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    if (!gstNo) {
      showErrorToast("GST number is required.");
      return;
    }

    if (!gstRegex.test(gstNo)) {
      showErrorToast("Invalid GST number format.");
      return;
    }

    setFetchingData(true);
    const timer = setTimeout(() => {
      setSowStep1Content(true);
      // setNoGstNo(true); // Uncomment this if necessary
      setFetchingData(false);
      getImportGstDetails(user?.business_id, gstNo);
    }, 1000); // Adjust the time as needed

    return () => clearTimeout(timer);
  };

  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    {
      index: 1,
      label: "Company Details",
      content: (
        <Step1Content
          companyLogo={companyLogo}
          handleFileDrop={handleFileDrop}
          setCompanyLogo={setCompanyLogo}
          fetchingData={fetchingData}
          handleImportClicked={handleImportClicked}
          showStep1Content={showStep1Content}
          noGstNo={noGstNo}
          errors={errors.step1}
          companyName={companyName}
          setCompanyName={setCompanyName}
          industry={industry}
          setIndustry={setIndustry}
          website={website}
          setWebsite={setWebsite}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          address1={address1}
          setAddress1={setAddress1}
          address2={address2}
          setAddress2={setAddress2}
          gstNo={gstNo}
          setGstNo={setGstNo}
        />
      ),
    },
    {
      index: 2,
      label: "Contact Details",
      content: (
        <Step2Content
          handleAddNewPerson={handleAddNewPerson}
          addNewPerson={addNewPerson}
          removePerson={removePerson}
          expandedIndex={expandedIndex}
          toggleExpand={toggleExpand}
          handleChange={handleChange}
          errors={errors.step2}
        />
      ),
    },
  ];

  return (
    <div className="bg-gray-100 pt-[5.3rem] px-[1.2rem] pb-[1rem]">
      <div className="min-h-[530px] bg-white p-[1rem] rounded-md border">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-[0.5rem] mb-[1rem]">
            <button
              onClick={() =>
                navigate(BUSINESS_NEW_CUSTOMERS_URL.replace(":type", "select"))
              }
            >
              <img src={previousBtn} alt="" className="w-[1rem] h-[1rem]" />
            </button>
            <h4 className="text-darkGray text-[0.9rem] m-0">
              New Customer - Registered
            </h4>
          </div>
          <button onClick={handleOpenDialog}>
            <img
              src={closeIcon}
              alt="closeicon"
              className="w-[0.7rem] h-[0.7rem]"
            />
          </button>
        </div>
        {openDialog && (
          <DiscardChangesDialog
            handleCloseDialog={handleCloseDialog}
            isOpen={openDialog}
            DiscardChanges={DiscardChanges}
          />
        )}

        <Stepper steps={steps} currentStep={currentStep} />

        {/* buttons */}
        {showStep1Content && (
          <div className="flex gap-[0.8rem] justify-end mt-[1rem]">
            <WhiteButton
              onClick={handleBackButton}
              disabled={currentStep === 1}
              name="Back"
              px={"px-[0.5rem]"}
              py={"py-[0.3rem]"}
            />
            <OrangeButton
              onClick={handleNextClicked}
              disabled={currentStep === steps.length}
              name="Next"
              px={"px-[0.5rem]"}
              py={"py-[0.3rem]"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCustomerRegistered;

const Step1Content = ({
  companyLogo,
  handleFileDrop,
  setCompanyLogo,
  handleImportClicked,
  fetchingData,
  showStep1Content,
  noGstNo,
  errors,
  companyName,
  setCompanyName,
  industry,
  setIndustry,
  website,
  setWebsite,
  phone,
  setPhone,
  email,
  setEmail,
  address1,
  setAddress1,
  address2,
  setAddress2,
  setGstNo,
  gstNo,
}) => {
  return (
    <div className="grid grid-cols-12">
      <div className="input-container w-[30%] col-span-12 mb-[0.8rem]">
        <label className="input-label">Import From GSTIN</label>
        <div className=" flex items-center border-1 border-[#E5E7EB] rounded-md ">
          {/* group focus-within:border-[#EA580C] */}
          {/* <InputField
            value={gstNo}
            onChange={(e) => setGstNo(e.target.value)}
            className={`${
              showStep1Content ? "text-[#14B8A6] " : "text-[#334155]"
            } ${
              noGstNo ? "text-[#EF4444] " : "text-[#334155]"
            } font-[500] text-[0.9rem] w-[80%] bg-white focus:outline-none p-[0.4rem] rounded-l`}
            placeholder="XX XXXXX XXXX XXXX"
          /> */}
          <InputField 
            type="select"
            value={gstNo}
            onChange={(e) => setGstNo(e.target.value)}
            className={`${
              showStep1Content ? "text-[#14B8A6] " : "text-[#334155]"
            } ${
              noGstNo ? "text-[#EF4444] " : "text-[#334155]"
            } font-[500] text-[0.9rem] w-[80%] bg-white focus:outline-none p-[0.4rem] rounded-l`}
            options={[
              {value:"", label:"XXXXXXXXXXX"},
              {value:"33GSPTN9771G3ZP", label:"33GSPTN9771G3ZP"}
            ]}
          />

          {showStep1Content ? (
            <button
              // onClick={handleImportClicked}
              className=" text-lightGray font-[500] bg-[#F1F5F9] px-[0.6rem] py-[0.5rem] text-[0.9rem] rounded-r-md"
            >
              Import
            </button>
          ) : (
            <button
              onClick={handleImportClicked}
              className={`${
                fetchingData ? "w-[20%] flex items-center justify-center" : ""
              }  text-white bg-[#EA580C] px-[0.6rem] py-[0.5rem] text-[0.9rem] rounded-r-md`}
            >
              {fetchingData ? (
                <div className="loader w-[1rem] h-[1rem] border-2 border-t-white border-gray-200 rounded-full animate-spin"></div>
              ) : (
                "Import"
              )}
            </button>
          )}
        </div>
      </div>
      {fetchingData && (
        <>
          <h4 className="col-span-8 text-[0.9rem] text-lightGray">
            Fetching Details....
          </h4>
          <div className="col-span-8">
            <div className="flex gap-[2rem]">
              <div className="w-[36%] h-10 bg-gray-300 rounded animate-pulse"></div>
              <div className="w-[36%] h-10 bg-gray-300 rounded animate-pulse"></div>
            </div>
            <div className="w-[36%] my-[1rem] h-10 bg-gray-300 rounded animate-pulse"></div>
            <div className="flex gap-[2rem]">
              <div className="w-[36%] h-10 bg-gray-300 rounded animate-pulse"></div>
              <div className="w-[36%] h-10 bg-gray-300 rounded animate-pulse"></div>
            </div>
            <div className="my-[1rem] w-[76%] h-10 bg-gray-300 rounded animate-pulse"></div>
            <div className="w-[76%] h-10 bg-gray-300 rounded animate-pulse"></div>
          </div>
          <div className="col-span-4">
            <div className="h-64 bg-gray-300 rounded animate-pulse"></div>
          </div>
        </>
      )}

      {noGstNo && (
        <h4 className="col-span-8 text-[0.9rem] text-[#EF4444] ">
          Our system couldn't find the GSTIN number please check your number and
          try again.
        </h4>
      )}

      {showStep1Content && (
        <>
          <div className="col-span-8">
            <div className="flex">
              <div className="input-container w-[40%]">
                <label className="input-label">Company Name</label>
                <InputField
                  className="input-box text-darkGray font-[500]"
                  placeholder="Name"
                  name="company_name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                {errors.companyName && (
                  <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                    {errors.companyName}
                  </p>
                )}
              </div>
              <div className="input-container w-[36%]">
                <label className="input-label">Industry</label>
                <div className="group focus-within:border-[#EA580C] flex items-center border-1 border-[#E5E7EB] rounded-md  relative">
                  <InputField
                    type="select"
                    className="text-darkGray font-[500] text-[0.9rem] w-[100%] bg-white focus:outline-none py-[0.6rem] px-[0.4rem] rounded-md"
                    options={[
                      { value: "", label: "Select Industry" },
                      { value: "1", label: "Consultancy" },
                      { value: "2", label: "Technology" },
                      { value: "3", label: "Construction" },
                      { value: "4", label: "Clothing" },
                      { value: "5", label: "Agriculture" },
                      { value: "6", label: "Salaried" },
                      { value: "7", label: "Real Estate" },
                      { value: "8", label: "Food & Beverage" },
                      { value: "9", label: "Consulting" },
                      { value: "10", label: "Rental" },
                      { value: "11", label: "Sports" },
                      { value: "12", label: "Decors" },
                      { value: "13", label: "Retail" },
                      { value: "14", label: "Healthcare" },
                    ]}
                    name="industry"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                  />
                  {/* <div className="relative border-1 border-black group-focus-within:text-[#EA580C] p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-r-md">
                    <img src={upDown} alt="" />
                   
                  </div> */}
                </div>
                {/* {errors.industry && (
                  <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                    {errors.industry}
                  </p>
                )} */}
              </div>
            </div>
            <div className="input-container w-[36%] my-[0.8rem]">
              <label className="input-label">Website</label>
              <div className="group focus-within:border-[#EA580C] flex items-center border-1 border-[#E5E7EB] rounded-md ">
                <span className="group-focus-within:text-[#EA580C] p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                  http://
                </span>
                <InputField
                  name="website"
                  className="text-darkGray font-[500] text-[0.9rem] w-[90%] bg-white focus:outline-none p-[0.4rem] rounded-r"
                  placeholder="www.yoursite.com"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              {/* {errors.website && (
                <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                  {errors.website}
                </p>
              )} */}
            </div>

            <div className="flex ">
              <div className="input-container w-[40%] ">
                <div className="flex justify-between items-center w-[90%]">
                  <label className="input-label">Phone</label>
                  <label className="text-[0.7rem] text-lightGray">
                    Required
                  </label>
                </div>
                <InputField
                  className="input-box text-darkGray font-[500]"
                  placeholder="+91 (000) 000-0000"
                  type="number"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {/* {errors.phone && (
                  <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                    {errors.phone}
                  </p>
                )} */}
              </div>

              <div className="input-container w-[40%] ">
                <div className="flex justify-between items-center w-[90%]">
                  <label className="input-label">Email</label>
                  <label className="text-[0.7rem] text-lightGray">
                    Optional
                  </label>
                </div>
                <InputField
                  className="input-box text-darkGray font-[500]"
                  placeholder="your@gmail.com"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="input-container w-[84%] my-[0.8rem]">
              <label className="input-label">Address(Registered)</label>
              <InputField
                className="input-box text-darkGray font-[500]"
                placeholder="Address 1"
                name="address1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              />
              {/* {errors.address1 && (
                <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                  {errors.address1}
                </p>
              )} */}
            </div>
            <div className="input-container w-[84%]">
              <label className="input-label">
                Address(Communication/Shipping)
              </label>
              <InputField
                className="input-box text-darkGray font-[500]"
                placeholder="Address 2"
                name="address1"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
              {/* {errors.address2 && (
                <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                  {errors.address2}
                </p>
              )} */}
            </div>
          </div>

          {/* photo */}
          <div
            onDrop={(e) => handleFileDrop(e)}
            onDragOver={(e) => e.preventDefault()}
            className="col-span-4 bg-[#F8FAFC] rounded-md flex flex-col justify-center items-center"
          >
            <h4 className="text-[0.9rem] text-darkGray font-[500]">
              Company Logo
            </h4>
            {companyLogo ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={URL.createObjectURL(companyLogo)} // Convert file object to a URL
                  alt="Company Logo"
                  className="w-16 h-16 rounded-full"
                />
                <button
                  className="text-[#EA580C] text-[0.9rem] font-[500] mt-[0.5rem]"
                  onClick={() => setCompanyLogo("")}
                >
                  Change
                </button>
              </div>
            ) : (
              <div
                className="bg-white rounded-md border flex flex-col justify-center items-center py-[2.5rem] px-[3.5rem] cursor-pointer"
                onClick={() => document.querySelector(`#company-logo`).click()}
              >
                <img src={uploadFile} alt="" />
                <h4 className="text-darkGray text-[0.9rem] font-[500]">
                  Drop your file here or{" "}
                  <span className="text-[#EA580C]">browse</span>
                </h4>
                <h6 className="text-[0.8rem] text-lightGray m-0 font-[400]">
                  Maximum size: 2MB
                </h6>
              </div>
            )}
            <InputField
              type="file"
              accept=".jpeg, .png, .jpg"
              id={`company-logo`}
              className="hidden"
              onChange={(e) => setCompanyLogo(e.target.files[0])}
            />
            {/* {errors.companyLogo && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.companyLogo}
              </p>
            )} */}
          </div>
        </>
      )}
    </div>
  );
};

const Step2Content = ({
  handleAddNewPerson,
  addNewPerson,
  removePerson,
  expandedIndex,
  handleChange,
  toggleExpand,
  errors,
}) => {
  return (
    <div className="">
      <div className="flex justify-between items-center mb-[1rem]">
        <h4 className="text-[0.9rem] m-0 font-[500]">Contact Person</h4>
        <button
          className="font-[500] text-[0.9rem] rounded-md text-[#9A3412] bg-[#FFEDD5] px-[0.5rem] py-[0.3rem]"
          onClick={handleAddNewPerson}
        >
          Add New Person
        </button>
      </div>
      <h4 className="text-[0.9rem] font-[500]">Primary</h4>
      <div className="overflow-y-auto max-h-[330px] scrollbar-hide">
        {addNewPerson.map((item, index) => (
          <div key={index} className="mb-[1rem]">
            {index > 0 && (
              <div className="flex justify-between">
                <h4 className="text-[0.9rem] font-[500]">
                  Contact Person #{index + 1}
                </h4>

                <button
                  className="text-[0.9rem] text-red-500 font-[500]"
                  onClick={() => removePerson(index)}
                >
                  Remove
                </button>
              </div>
            )}

            <div className="border rounded-md p-[1rem] bg-[#F8FAFC]">
              {expandedIndex[index] ? (
                <>
                  <div className="flex">
                    <div className="input-container w-[40%] flex flex-col">
                      <label className="input-label">Name</label>
                      <InputField
                        onChange={(e) =>
                          handleChange(index, "name", e.target.value)
                        }
                        placeholder="Name"
                        value={item.name}
                        name="person_name"
                        className="input-box text-darkGray font-[500]"
                      />
                      {errors[`name_${index}`] && (
                        <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                          {errors[`name_${index}`]}
                        </p>
                      )}
                    </div>
                    <div className="input-container w-[40%] flex flex-col">
                      <label className="input-label">Role</label>
                      <InputField
                        onChange={(e) =>
                          handleChange(index, "role", e.target.value)
                        }
                        placeholder="Role"
                        name="role"
                        value={item.role}
                        className="input-box text-darkGray font-[500] "
                      />
                      {/* {errors[`role_${index}`] && (
                        <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                          {errors[`role_${index}`]}
                        </p>
                      )} */}
                    </div>
                  </div>

                  <div className="flex my-[0.8rem]">
                    <div className="input-container w-[40%] ">
                      <div className="flex justify-between items-center w-[90%]">
                        <label className="input-label">Phone</label>
                        <label className="text-[0.7rem] text-lightGray">
                          Required
                        </label>
                      </div>
                      <InputField
                        onChange={(e) =>
                          handleChange(index, "phone", e.target.value)
                        }
                        value={item.phone}
                        className="input-box text-darkGray font-[500]"
                        placeholder="+91 (000) 000-0000"
                        type="number"
                        name="phone_number"
                      />
                      {/* {errors[`phone_${index}`] && (
                        <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                          {errors[`phone_${index}`]}
                        </p>
                      )} */}
                    </div>

                    <div className="input-container w-[40%] ">
                      <div className="flex justify-between items-center w-[90%]">
                        <label className="input-label">Email</label>
                        <label className="text-[0.7rem] text-lightGray">
                          Required
                        </label>
                      </div>
                      <InputField
                        onChange={(e) =>
                          handleChange(index, "email", e.target.value)
                        }
                        value={item.email}
                        className="input-box text-darkGray font-[500]"
                        placeholder="your@gmail.com"
                        type="email"
                        name="email"
                      />
                      {/* {errors[`email_${index}`] && (
                        <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                          {errors[`email_${index}`]}
                        </p>
                      )} */}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="input-container w-[40%] ">
                      <div className="flex justify-between items-center w-[90%]">
                        <label className="input-label">PAN Card</label>
                        <label className="text-[0.7rem] text-lightGray">
                          Optional
                        </label>
                      </div>
                      <InputField
                        onChange={(e) =>
                          handleChange(index, "panNo", e.target.value)
                        }
                        value={item.panNo}
                        className="input-box text-darkGray font-[500]"
                        placeholder="AAAPA1234A"
                        type="type"
                        name="pancard"
                      />
                    </div>
                    <div className="mt-[1rem]">
                      <button className="m-0 text-[0.9rem] font-[500] px-[1rem] py-[0.4rem] rounded-md bg-[#F3F4F6] text-[#1F2937]">
                        Verify PAN
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  onClick={() => toggleExpand(index)}
                  className="flex justify-between items-center cursor-pointer"
                >
                  <h4 className="text-[0.9rem] font-[500] m-0">{item.name}</h4>
                  <img
                    src={arrow}
                    alt=""
                    className="transform -rotate-180 w-[1rem] h-[1rem]"
                  />{" "}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
