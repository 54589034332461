import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import usePatchCustomerDetail from "../data/usePatchCustomerDetails";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

const EditPrimaryContact = ({
  triggerElement,
  openProfileEdit,
  setOpenProfileEdit,
  detailData,
  contactId,
  setReCallApi,
}) => {
  const [isClose, setIsClose] = useState(false);
  const [errors, setErrors] = useState({});
  const [contactIndex, setContactIndex] = useState("");
  const [userId, setUserId] = useState("");
  const [primary, setPrimary] = useState(false);
  const businessId = JSON.parse(localStorage.getItem("user")).business_id;
  const [contactFormData, setContactFormData] = useState({
    name: "",
    role: "",
    phone: "",
    email: "",
    pan_card: "",
    id: "",
  });

  const [patchData, patchError, patchIsLoading, patchCustomerDetail] =
    usePatchCustomerDetail();

  useEffect(() => {
    if (patchData) {
      // showSuccessToast("Data Updated Successfully")
      showSuccessToast(
        "Successfully updated.",
        "top-right",
        `You have successfully updated contact ${patchData?.data?.contact_persons[0]?.name}.`
      );

      setOpenProfileEdit(false);
      setReCallApi(true);
    }
    if (patchError) {
      showErrorToast("Error in uploading data");
    }
  }, [patchData, patchError]);

  useEffect(() => {
    if (detailData && contactId) {
      setUserId(detailData?.customer?.id);
      const editObj = detailData?.customer?.contact_persons?.filter((el, i) => {
        if (el.id === contactId) {
          setContactIndex(i);
          return true;
        }
      });
      const { customer, is_primary, ...rest } = editObj?.[0];
      setPrimary(is_primary);
      setContactFormData(rest);
    }
  }, [detailData, contactId]);
  const handleSubmit = () => {
    setErrors({});
    const errorObj = validateFields();
    if (Object.keys(errorObj).length > 0) {
      setErrors(errorObj);
      return;
    }
    const formData = new FormData();
    Object.keys(contactFormData).map((el, i) => {
      formData.append(
        `contact_persons.${contactIndex}.${el}`,
        contactFormData[el]
      );
    });
    patchCustomerDetail(formData, businessId, userId);
  };
  const resetState = () => {
    if (openProfileEdit) {
      setOpenProfileEdit(false);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10,15}$/; // 10 to 15 digits allowed

    if (!contactFormData.name) {
      newErrors[`name`] = "Name is required.";
    }
    // else if (!/^[A-Za-z\s]+$/.test(contactFormData.name.trim())) {
    //   newErrors[`name`] = "Name can only containe letters.";
    // }

    // if (!contactFormData.role) {
    //   newErrors[`role`] = "Role is required.";
    // } else if (!/^[A-Za-z\s]+$/.test(contactFormData.role.trim())) {
    //   newErrors[`role`] = "Role can only containe letters.";
    // }
    // if (!contactFormData.phone) {
    //   newErrors[`phone`] = "Phone is required.";
    // } else if (!phoneRegex.test(contactFormData.phone)) {
    //   newErrors[`phone`] = " Enter a valid phone number";
    // }

    // if (!contactFormData.email) {
    //   newErrors[`email`] = "Email is required.";
    // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactFormData.email)) {
    //   newErrors[`email`] = "Invalid email format.";
    // }
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prev) => ({ ...prev, [name]: value }));
  };
  const getContent = () => {
    return (
      <div className="mb-[0.5rem]">
        <div className="flex">
          <div className="input-container w-[50%] flex flex-col">
            <label className="input-label">Name</label>
            <InputField
              placeholder="Name"
              name="name"
              value={contactFormData.name}
              onChange={handleInputChange}
              className="input-box text-darkGray font-[500]"
            />
            {errors.name && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.name}
              </p>
            )}
          </div>
          <div className="input-container w-[50%] flex flex-col">
            <label className="input-label">Role</label>
            <InputField
              placeholder="Role"
              name="role"
              value={contactFormData.role}
              onChange={handleInputChange}
              className="input-box text-darkGray font-[500] "
            />
            {errors.role && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.role}
              </p>
            )}
          </div>
        </div>
        <div className="flex my-[0.5rem]">
          <div className="input-container w-[50%] ">
            <div className="flex justify-between items-center w-[90%]">
              <label className="input-label">Phone</label>
              <label className="text-[0.7rem] text-lightGray">Required</label>
            </div>
            <InputField
              className="input-box text-darkGray font-[500] appearance-none"
              placeholder="+91 (000) 000-0000"
              type="number"
              name="phone"
              value={contactFormData.phone}
              onChange={handleInputChange}
            />
            {errors.phone && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.phone}
              </p>
            )}
          </div>

          <div className="input-container w-[50%] ">
            <div className="flex justify-between items-center w-[90%]">
              <label className="input-label">Email</label>
              <label className="text-[0.7rem] text-lightGray">Required</label>
            </div>
            <InputField
              className="input-box text-darkGray font-[500]"
              placeholder="your@gmail.com"
              type="email"
              name="email"
              value={contactFormData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.email}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="input-container w-[50%] ">
            <div className="flex justify-between items-center w-[90%]">
              <label className="input-label">PAN Card</label>
              <label className="text-[0.7rem] text-lightGray">Optional</label>
            </div>
            <InputField
              className="input-box text-darkGray font-[500]"
              placeholder="AAAPA1234A"
              type="text"
              name="pan_card"
              value={contactFormData.pan_card}
              onChange={handleInputChange}
            />
            {errors.pan_card && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.pan_card}
              </p>
            )}
          </div>
          <div className="mt-[1rem]">
            <button className="m-0 text-[0.9rem] font-[500] px-[1rem] py-[0.4rem] rounded-md bg-[#F3F4F6] text-[#1F2937]">
              Verify PAN
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <DialogBox
        triggerElement={triggerElement}
        heading={`Edit ${primary ? "Primary" : ""} Contact`}
        width="w-[750px]"
        onClose={resetState}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                bg={"bg-[#FB923C]"}
                onClick={handleSubmit}
              />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default EditPrimaryContact;
