import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import OtpModal from "components/OtpDialog/OtpModal";
import InputField from "components/input/InputField";
import GoogleLogin from "containers/LoginAndSignUp/GoogleLogin";

// images
import logo from "assets/images/dashboard/logo.svg";
import loginView from "assets/images/login/LoginFrontView.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// APIs
import usePostNewSignUp from "./data/usePostNewSignup";
import usePostVerifyOtp from "./data/usePostVerifyOtp";
import usePostResendOtp from "./data/usePostResendOtp";

const SignupBusiness = ({ toHidePathname }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userType, setUserType] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [fullName, setfullName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [state, setstate] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [businessOwnerName, setbusinessOwnerName] = useState("");
  const [purposeId, setPurposeId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpSessionId, setOtpSessionId] = useState(null);
  const [postData, postError, postIsLoading, postSignup] = usePostNewSignUp();
  const [postOtpData, postOtpError, postOtpIsLoading, postOtp] =
    usePostVerifyOtp();
  const [
    postResendOtpData,
    postResendOtpError,
    postResendOtpIsLoading,
    postResendOtp,
  ] = usePostResendOtp();

  const handleUserType = () => {
    setUserType(!userType);
  };

  const validateFields = () => {
    let newErrors = {};
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

    if (userType == false) {
      if (!password.trim()) {
        newErrors.password = "Password is required";
      } else if (password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      } else if (!passwordRegex.test(password)) {
        newErrors.password =
          "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character";
      }

      if (!mobileNo.trim()) {
        newErrors.mobileNo = "Mobile number is required";
      } else if (!/^[1-9]\d{9}$/.test(mobileNo)) {
        newErrors.mobileNo =
          "Mobile number must be exactly 10 digits and cannot start with 0";
      }

      if (!fullName.trim()) {
        newErrors.fullName = "Full name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(fullName)) {
        newErrors.fullName =
          "Full name cannot contain numbers or special characters";
      }

      if (!email.trim()) {
        newErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        newErrors.email = "Invalid email format";
      }

      // if (!state.trim()) {
      //   newErrors.state = "State is required";
      // }
      // if (!dateOfBirth.trim()) {
      //   newErrors.dateOfBirth = "Date of birth is required";
      // }
    }

    if (userType) {
      if (!businessName.trim()) {
        newErrors.businessName = "Business name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(businessName)) {
        newErrors.businessName =
          "Business name cannot contain numbers or special characters";
      }
      if (!businessOwnerName.trim()) {
        newErrors.businessOwnerName = "Business owner name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(businessOwnerName)) {
        newErrors.businessOwnerName =
          "Business Owner name cannot contain numbers or special characters";
      }
      if (!email.trim()) {
        newErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        newErrors.email = "Invalid email format";
      }
      if (!password.trim()) {
        newErrors.password = "Password is required";
      } else if (password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      } else if (!passwordRegex.test(password)) {
        newErrors.password =
          "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character";
      }
      if (!mobileNo.trim()) {
        newErrors.mobileNo = "Mobile number is required";
      } else if (!/^[1-9]\d{9}$/.test(mobileNo)) {
        newErrors.mobileNo =
          "Mobile number must be exactly 10 digits and cannot start with 0";
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (postError) {
      const errorMessage =
        postError?.err?.response?.data?.data?.non_field_errors;
      // console.log(
      //   "errorMsg",
      //   postError.err.response.data.data.non_field_errors
      // );
      showErrorToast(errorMessage);
    }
  }, [postError]);

  useEffect(() => {
    if (postOtpError) {
      showErrorToast("Invalid OTP");
    }
  }, [postOtpError]);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Registration Successful");
      setIsModalOpen(true);
      setMobileNo(mobileNo);
      setOtpSessionId(postData.data.otp_session_id);
      setPurposeId(postData.data.purpose);
    }
  }, [postData]);

  useEffect(() => {
    if (postResendOtpError) {
      showErrorToast("Otp not resented");
    }
  }, [postResendOtpError]);

  const handleCreateAccount = () => {
    if (validateFields()) {
      if (userType == false) {
        let postDetails = {
          account_type: 1,
          mobile_number: mobileNo,
          full_name: fullName,
          email: email,
          password: password,
        };
        postSignup(postDetails);
      } else {
        let postDetails = {
          account_type: 2,
          business_name: businessName,
          business_owner_name: businessOwnerName,
          mobile_number: mobileNo,
          email: email,
          password: password,
        };
        postSignup(postDetails);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCreateAccount();
    }
  };

  useEffect(() => {
    if (postOtpData) {
      showSuccessToast("Otp Submitted Successfully.");
      setIsModalOpen(false); // Close the modal after submitting OTP
      // if (userType) {
      //   navigate("/businessDetails", {
      //     state: { user_id: postData.data.user_id },
      //   });
      // } else {
      navigate("/newLogin");
      // }
    }
  }, [postOtpData]);

  useEffect(() => {
    if (postResendOtpData) {
      showSuccessToast("Otp resent Successfully");
      setOtpSessionId(postResendOtpData.data.otp_session_id);
    }
  }, [postResendOtpData]);

  const handleOtpSubmit = (otp) => {
    postOtp({ otp_session_id: otpSessionId, otp: otp, purpose: purposeId });
  };

  const handleResend = () => {
    postResendOtp({ mobile_number: mobileNo, purpose: purposeId });
  };

  const SignUpWithGoogle = () => {
    navigate("/signUpMobileVerification");
  };

  return (
    <>
      <div className="bg-[#FFFFFF] w-full min-h-screen flex scrollbar-hide">
        <div
          className={`w-[100%] min-h-screen flex items-center justify-center ${
            toHidePathname ? "md:w-[100%] mt-[10rem] " : "md:w-[50%]"
          }`}
        >
          {/* Content goes here */}

          <div className="md:w-[60%] w-[80%] py-5">
            {!toHidePathname && (
              <Link to="/" className="no-underline">
                <div className="flex flex-col items-center">
                  <img src={logo} alt="logo" width="60%" />
                  <p className="m-0 text-[#334155] text-[0.9rem]">
                    All-in-one financial care
                  </p>
                </div>
              </Link>
            )}
            <div className="md:w-60% flex flex-col items-center mt-4 gap-1">
              <p className="m-0 text-[#334155] text-[0.95rem] md:text-[1rem] font-[600]">
                Create a New Account
              </p>
              <div className="bg-[#F3EFF8] rounded-xl px-3 py-2.5 md:w-[52%] flex justify-between">
                <span
                  onClick={handleUserType}
                  className={` font-[600] py-2 px-2.5  md:text-[0.9rem] text-[0.83rem] cursor-pointer ${
                    userType ? "" : "bg-[#FFFFFF] shadow-sm rounded-xl"
                  }`}
                >
                  Personal
                </span>
                <span
                  onClick={handleUserType}
                  className={`font-[600] py-2 px-2.5 md:text-[0.9rem] text-[0.83rem] cursor-pointer ${
                    userType ? "bg-[#FFFFFF] shadow-sm rounded-xl" : ""
                  }`}
                >
                  Business
                </span>
              </div>
            </div>
            {userType ? (
              <>
                <SignupBusinessUser
                  businessName={businessName}
                  setbusinessName={setbusinessName}
                  businessOwnerName={businessOwnerName}
                  setbusinessOwnerName={setbusinessOwnerName}
                  mobileNo={mobileNo}
                  setMobileNo={setMobileNo}
                  email={email}
                  setemail={setemail}
                  password={password}
                  setpassword={setpassword}
                  handleCreateAccount={handleCreateAccount}
                  errors={errors}
                  handleKeyDown={handleKeyDown}
                  SignUpWithGoogle={SignUpWithGoogle}
                />
              </>
            ) : (
              <>
                <SignupPersonalUser
                  fullName={fullName}
                  setfullName={setfullName}
                  mobileNo={mobileNo}
                  setMobileNo={setMobileNo}
                  email={email}
                  setemail={setemail}
                  password={password}
                  setpassword={setpassword}
                  state={state}
                  // setstate={setstate}
                  // dateOfBirth={dateOfBirth}
                  errors={errors}
                  handleKeyDown={handleKeyDown}
                  setdateOfBirth={setdateOfBirth}
                  handleCreateAccount={handleCreateAccount}
                  SignUpWithGoogle={SignUpWithGoogle}
                />
              </>
            )}
          </div>
        </div>

        {!toHidePathname && (
          <div className="w-[50%] min-h-screen border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
            <div className="flex flex-col items-center h-screen justify-center">
              <div>
                <img src={loginView} alt="loginView" />
              </div>
              <div className="flex flex-col items-center">
                <p className="m-0 text-[#334155] text-[1rem] font-[600]">
                  Simplify Your Finances with Beyondtax
                </p>
                <p className="m-0 text-[#334155] text-[0.9rem]">
                  Manage your finances, tax, and compliance all in one place.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <OtpModal
          otp_session_id={otpSessionId}
          mobileNo={mobileNo}
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          handleOtpSubmit={handleOtpSubmit}
          handleResend={handleResend}
        />
      )}
    </>
  );
};

export default SignupBusiness;

const SignupPersonalUser = ({
  fullName,
  setfullName,
  mobileNo,
  setMobileNo,
  email,
  setemail,
  password,
  setpassword,
  // state,
  // setstate,
  // dateOfBirth,
  // setdateOfBirth,
  onKeyDown,
  handleKeyDown,
  errors,
  handleCreateAccount,
  SignUpWithGoogle,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="md:w-[90%] p-2 mt-2 flex flex-col justify-center gap-3">
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Full Name
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          type="text"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter Full Name"
          value={fullName}
          onChange={(e) => setfullName(e.target.value)}
          name="fullName"
          validation={{ required: true }}
        />

        {errors.fullName && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.fullName}</p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Mobile
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          // type="number"
          type="tel"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter Mobile Number"
          value={mobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          name="mobileNo"
          validation={{ required: true }}
        />
        {errors.mobileNo && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.mobileNo}</p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          E-mail
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          type="email"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter E-mail"
          value={email}
          onChange={(e) => setemail(e.target.value)}
          name="email"
          validation={{ required: true }}
        />
        {errors.email && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.email}</p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Password
        </p>

        <div className="relative w-full">
          <InputField
            onKeyDown={handleKeyDown}
            type={showPassword ? "text" : "password"} // Toggle between text and password
            className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5 pr-10" // Added pr-10 for space for the eye icon
            placeholder="Create Password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            name="password"
            validation={{ required: true }}
          />

          {/* Eye toggle icon */}
          <span
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
          </span>
        </div>

        <p className="m-0 text-[#64748B] flex items-center justify-end text-[0.8rem] font-[500]">
          Must be at least 8 characters.
        </p>
        {errors.password && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.password}</p>
        )}
      </div>

      <div>
        <p className="m-0 text-[#334155] md:text-[0.9rem] text-[0.8rem]">
          By creating account, you agree to our{" "}
          <span className="font-[600]">Terms of Service & Privacy Policy</span>
        </p>
      </div>
      <div className="">
        <button
          className="text-[#FFFFFF] bg-[#94A3B8] rounded-md px-2 py-1.5 w-[100%]"
          onClick={handleCreateAccount}
          // disabled={!isFormValid}
        >
          Create Account
        </button>
      </div>
      <div className="flex items-center justify-center">
        <p className="m-0 text-[#334155] text-[0.9rem]">OR</p>
      </div>
      <div className="">
        <GoogleLogin />
      </div>
      <div className="flex flex-col items-center">
        <p className="m-0 text-[#667085] text-[0.9rem]">
          Already have an account?
          <Link to="/newLogin" className="text-[#9065B4] ml-2 cursor-pointer">
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
};

const SignupBusinessUser = ({
  businessName,
  setbusinessName,
  businessOwnerName,
  setbusinessOwnerName,
  mobileNo,
  setMobileNo,
  email,
  setemail,
  password,
  setpassword,
  handleKeyDown,
  errors,
  handleCreateAccount,
  SignUpWithGoogle,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="md:w-[90%] p-2 mt-2 flex flex-col justify-center gap-3">
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Business Name
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          type="text"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter Full Name"
          value={businessName}
          onChange={(e) => setbusinessName(e.target.value)}
          name="businessName"
          validation={{ required: true }}
        />
        {errors.businessName && (
          <p className="text-[0.9rem] text-red-600 m-0">
            {errors.businessName}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Full Name of Business Representative
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          type="text"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter Full Name"
          value={businessOwnerName}
          onChange={(e) => setbusinessOwnerName(e.target.value)}
          name="businessOwnerName"
          validation={{ required: true }}
        />
        {errors.businessOwnerName && (
          <p className="text-[0.9rem] text-red-600 m-0">
            {errors.businessOwnerName}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Mobile
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          type="text"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter Mobile Number"
          value={mobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          name="mobileNo"
          validation={{ required: true }}
        />
        {errors.mobileNo && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.mobileNo}</p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          E-mail
        </p>
        <InputField
          onKeyDown={handleKeyDown}
          type="text"
          className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
          placeholder="Enter E-mail"
          value={email}
          onChange={(e) => setemail(e.target.value)}
          name="email"
          validation={{ required: true }}
        />
        {errors.email && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.email}</p>
        )}
      </div>
      <div className="flex flex-col gap-1 ">
        <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[600]">
          Password
        </p>
        <div className="relative w-full">
          <InputField
            onKeyDown={handleKeyDown}
            type={showPassword ? "text" : "password"} // Toggle between text and password
            className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5 pr-10" // Added pr-10 for space for the eye icon
            placeholder="Create Password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            name="password"
            validation={{ required: true }}
          />

          {/* Eye toggle icon */}
          <span
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
          </span>
        </div>
        <p className="m-0 text-[#64748B] flex items-center justify-end text-[0.8rem] font-[500]">
          Must be at least 8 characters.
        </p>
        {errors.password && (
          <p className="text-[0.9rem] text-red-600 m-0">{errors.password}</p>
        )}
      </div>
      <div>
        <p className="m-0 text-[#334155] md:text-[0.9rem] text-[0.8rem]">
          By creating account, you agree to our{" "}
          <span className="font-[600]">Terms of Service & Privacy Policy</span>
        </p>
      </div>
      <div className="">
        <button
          className="text-[#FFFFFF] bg-[#94A3B8] rounded-md px-2 py-1.5 w-[100%]"
          onClick={handleCreateAccount}
        >
          Create Account
        </button>
      </div>
      <div className="flex items-center justify-center">
        <p className="m-0 text-[#334155] text-[0.9rem]">OR</p>
      </div>
      <div className="">
        {/* <button
          onClick={SignUpWithGoogle}
          className="text-[#344054] bg-[#FFFFFF] rounded-md w-[100%] border-[1px] border-[#D0D5DD] flex items-center justify-center gap-1 px-2 py-1.5 text-[0.9rem] font-[600]"
        >
          <img src={googleLogo} alt="googleLogo" className="mr-1" />
          Sign up with Google
        </button> */}
        <GoogleLogin />
      </div>
      <div className="flex flex-col items-center">
        <p className="m-0 text-[#667085] text-[0.9rem]">
          Already have an account?
          <Link to="/newLogin" className="text-[#9065B4] ml-2 cursor-pointer">
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
};
