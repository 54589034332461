export const BASE_API_URL =
  process.env.REACT_APP_API_URL || "https://stage-api1.beyondtaxindia.com";
// process.env.REACT_APP_API_URL || "https://stage-api1.beyondtax.co ";

export const GET_HOME_API_URL = `${BASE_API_URL}/home`;
export const GET_SERVICE_API_URL = `${BASE_API_URL}/beyondTaxServices/service-pages?slug=:slug`;
export const GET_BLOG_API_URL = `${BASE_API_URL}/blog`;
export const POST_LOGIN_API_URL = `${BASE_API_URL}/accounts/api/login/?=`;
export const POST_REGISTRAION_API_URL = `${BASE_API_URL}/accounts/api/registration/`;
export const POST_OTP_API_URL = `${BASE_API_URL}/accounts/api/verify_otp/`;
export const GET_USER_DETAILS_URL = `${BASE_API_URL}/accounts/api/get-profile/`;
export const GET_ORDER_DETAILS_URL = `${BASE_API_URL}/workorder/list-work-order/`;
export const POST_LOGOUT_URL = `${BASE_API_URL}/accounts/api/logout/`;
export const POST_WORK_ORDER_URL = `${BASE_API_URL}/workorder/create-work-order/`;
export const DELETE_WORK_ORDER_URL = `${BASE_API_URL}/workorder/delete-uploaded-document/:deleteId`;
export const PATCH_UPDATE_USER_URL = `${BASE_API_URL}/accounts/api/update-profile/`;
export const PATCH_USER_TYPE_URL = `${BASE_API_URL}/accounts/api/update-user-type/`;
export const GET_SERVICES_LIST_URL = `${BASE_API_URL}/beyondTaxServices/services-list/`;
export const GET_BLOGS_LIST_URL = `${BASE_API_URL}/blogs/getblogpost/`;
export const GET_SUMMARY_LIST_URL = `${BASE_API_URL}/workorder/workorder-status-summary/`;
export const GET_BLOG_DETAILS_URL = `${BASE_API_URL}/blogs/blogpost-detail`;
export const POST_UPLOAD_DOCUMENTS_URL = `${BASE_API_URL}/workorder/upload-documents/`;
export const GET_ORDER_UPLOADED_DOCUMENTS = `${BASE_API_URL}/workorder/get-uploaded-documents/:work_order_id`;
export const POST_UPLOAD_DOCUMENTS_BY_BT_URL = `${BASE_API_URL}/workorder/upload-documents-beyondtax/`;
export const GET_DOWNLOAD_DOCUMENT_URL = `${BASE_API_URL}/workorder/workorder-download-document/`;
export const GET_DOWNLOAD_DOCUMENT_LIST_URL = `${BASE_API_URL}/workorder/workorder-download-document-list/`;
export const GET_UPCOMING_DUE_DATES_URL = `${BASE_API_URL}/accounts/api/upcoming-due-dates-monthly/`;
export const GET_USER_BASIC_DETAILS_URL = `${BASE_API_URL}/accounts/api/user-basic-details/`;
export const POST_GOOGLE_TOKEN_URL = `${BASE_API_URL}/auth/api/login/google/?code=`;
export const POST_SEND_OTP_API_URL = `${BASE_API_URL}/accounts/api/send-email-otp/`;
export const POST_RESEND_OTP_API_URL = `${BASE_API_URL}/accounts/api/resend-email-otp/`;
export const POST_VALIDATE_OTP_API_URL = `${BASE_API_URL}/accounts/api/verify-email-otp/`;
export const POST_SET_PSWD_API_URL = `${BASE_API_URL}/accounts/api/reset-password/`;
export const POST_CREATE_PROFILE_DETAILS_URL = `${BASE_API_URL}/incomeTax/create-incometax-profile/`;
// export const POST_CREATE_BANK_DETAILS_URL = `${BASE_API_URL}/incomeTax/create-incometax-bank-details/`
export const GET_PROFILE_DETAILS_URL = `${BASE_API_URL}/incomeTax/retrive-incometax-profile/`;
export const PATCH_UPDATE_PROFILE_URL = `${BASE_API_URL}/incomeTax/update-incometax-profile/`;

// PAN

export const GET_IT_RETURNS_URL = `${BASE_API_URL}/incomeTax/income-tax-returns/`;
export const POST_SEND_PAN_OTP = `${BASE_API_URL}/incomeTax/send-pan-verification-otp/`;
export const POST_RESEND_PAN_OTP = `${BASE_API_URL}/incomeTax/resend-pan-otp/`;
export const POST_VALIDATE_PAN_OTP_URL = `${BASE_API_URL}/incomeTax/verify-pan-otp/`;
export const POST_IMPORT_IT_PROFILE_DATA_URL = `${BASE_API_URL}/incomeTax/import-tax-profile-data/`;

// INCOME TAX SUMMARY
export const POST_DEDUCTIONS_URL = `${BASE_API_URL}/incomeTax/deductions/:income_tax_return_id/`;
export const GET_DEDUCTIONS_URL = `${BASE_API_URL}/incomeTax/deductions/:income_tax_return_id/`;
export const PATCH_DEDUCTIONS_URL = `${BASE_API_URL}/incomeTax/deductions/:income_tax_return_id/`;

export const GET_TOTAL_INCOME_URL = `${BASE_API_URL}/incomeTax/total-income/:income_tax_return_id/`;
export const GET_TOTAL_TAX_SUMMARY_URL = `${BASE_API_URL}/incomeTax/total-tax-summary/:income_tax_return_id/`;

export const GET_SALARY_INCOME_URL = `${BASE_API_URL}/incomeTax/salary-incomes/:income_tax_return_id/`;
export const POST_SALARY_INCOME_URL = `${BASE_API_URL}/incomeTax/salary-incomes/:income_tax_return_id/`;
export const PATCH_SALARY_INCOME_URL = `${BASE_API_URL}/incomeTax/salary-incomes/:income_tax_return_id/update/`;

export const GET_TAX_PAID_URL = `${BASE_API_URL}/incomeTax/tax-paid/:income_tax_return_id/`;
export const POST_TAX_PAID_URL = `${BASE_API_URL}/incomeTax/tax-paid/:income_tax_return_id/`;
export const PATCH_TAX_PAID_URL = `${BASE_API_URL}/incomeTax/tax-paid/:income_tax_return_id/`;

export const GET_CAPITAL_GAINS_URL = `${BASE_API_URL}/incomeTax/capital-gains/:income_tax_return_id/`;
export const POST_CAPITAL_GAINS_URL = `${BASE_API_URL}/incomeTax/capital-gains/:income_tax_return_id/`;
export const PATCH_CAPITAL_GAINS_URL = `${BASE_API_URL}/incomeTax/capital-gains/:income_tax_return_id/update/`;

export const POST_TAX_SUMMARY_RENTAL_URL = `${BASE_API_URL}/incomeTax/rental-incomes/:income_tax_return_id/`;
export const GET_TAX_SUMMARY_RENTAL_URL = `${BASE_API_URL}/incomeTax/rental-incomes/:income_tax_return_id/`;
export const PATCH_TAX_SUMMARY_RENTAL_URL = `${BASE_API_URL}/incomeTax/rental-incomes/:income_tax_return_id/update/`;

export const POST_TAX_SUMMARY_OTHERS_URL = `${BASE_API_URL}/incomeTax/other-incomes/:income_tax_return_id/`;
export const GET_TAX_SUMMARY_OTHERS_URL = `${BASE_API_URL}/incomeTax/other-incomes/:income_tax_return_id/`;
export const PATCH_TAX_SUMMARY_OTHERS_URL = `${BASE_API_URL}/incomeTax/other-incomes/:income_tax_return_id/`;
export const GET_TAX_SUMMARY_BUSINESS_URL = `${BASE_API_URL}/incomeTax/business-incomes/:income_tax_return_id/`;
export const POST_TAX_SUMMARY_BUSINESS_URL = `${BASE_API_URL}/incomeTax/business-incomes/:income_tax_return_id/`;
export const PATCH_TAX_SUMMARY_BUSINESS_URL = `${BASE_API_URL}/incomeTax/business-incomes/:income_tax_return_id/update/`;
export const GET_TAX_SUMMARY_AGRI_URL = `${BASE_API_URL}/incomeTax/agriculture-and-exempt-incomes/:income_tax_return_id/`;
export const POST_TAX_SUMMARY_AGRI_URL = `${BASE_API_URL}/incomeTax/agriculture-and-exempt-incomes/:income_tax_return_id/`;
export const PATCH_TAX_SUMMARY_AGRI_URL = `${BASE_API_URL}/incomeTax/agriculture-and-exempt-incomes/:income_tax_return_id/`;

export const POST_UPLOAD_AIS = `${BASE_API_URL}/incomeTax/upload-ais-pdf/:income_tax_return_id/`;
export const POST_UPLOAD_26AS_URL = `${BASE_API_URL}/incomeTax/upload-26as-pdf/:income_tax_return_id/`;
export const POST_UPLOAD_CHALLAN_URL = `${BASE_API_URL}/incomeTax/upload-challan-pdf/:income_tax_return_id/`;
export const PATCH_UPLOAD_CHALLAN_URL = `${BASE_API_URL}/incomeTax/update-challan-pdf/:income_tax_return_id/`;

// REPORTS
export const GET_REPORTS_URL = `${BASE_API_URL}/incomeTax/reports-page/`;
export const GET_REPORTS_YEAR_WISE_URL = `${BASE_API_URL}/incomeTax/reports-page/:income_year/`;
export const USE_GET_DOWNLOAD_26AS_URL = `${BASE_API_URL}/incomeTax/download-26as/:income_year/`;
export const USE_GET_DOWNLOAD_AIS_URL = `${BASE_API_URL}/incomeTax/download-ais/:income_year/`;
export const USE_GET_DOWNLOAD_TIS_URL = `${BASE_API_URL}/incomeTax/download-tis/:income_year/`;

export const GET_TAX_SUMMARY_URL = `${BASE_API_URL}/incomeTax/tax-summary/:income_tax_return_id/`;
export const GET_TAX_SUMMARY_PDF_URL = `${BASE_API_URL}/incomeTax/itr-summary-pdf/:income_tax_return_id/`;

export const GET_TAX_PERSONAL_PROFILE_DETAILS_URL = `${BASE_API_URL}/accounts/api/personal-profile-details/`;
export const PATCH_TAX_PROFILE_BASIC_DETAILS_URL = `${BASE_API_URL}/accounts/api/profile-basic-details/`;
export const POST_TAX_PROFILE_ADDRESS_URL = `${BASE_API_URL}/accounts/api/profile-addresses/`;
export const POST_TAX_PROFILE_PASSWORD_CHANGE_URL = `${BASE_API_URL}/accounts/api/change-password/`;
export const POST_TAX_PROFILE_MOBILE_CHANGE_URL = `${BASE_API_URL}/accounts/api/send-mobile-change-otp/`;
export const POST_TAX_PROFILE_MOBILE_OTP_URL = `${BASE_API_URL}/accounts/api/verify-mobile-change-otp/`;
export const POST_TAX_PROFILE_EMAIL_CHANGE_URL = `${BASE_API_URL}/accounts/api/send-email-change-otp/`;
export const POST_TAX_PROFILE_EMAIL_OTP_URL = `${BASE_API_URL}/accounts/api/verify-email-change-otp/`;
export const PATCH_TAX_PROFILE_PIC_ADD_UPDATE_URL = `${BASE_API_URL}/accounts/api/profile-picture-update/`;
export const DELETE_TAX_PROFILE_PIC_URL = `${BASE_API_URL}/accounts/api/profile-picture-delete/`;
export const GET_TAX_PROFILE_INFORMATION_URL = `${BASE_API_URL}/accounts/api/financial-ownership-details/`;
export const POST_TAX_PROFILE_INFORMATION_URL = `${BASE_API_URL}/accounts/api/financial-ownership-details/`;
// TAX COMPUTATION
export const USE_GET_OLD_REGIME_URL = `${BASE_API_URL}/incomeTax/computations-old-regime/:income_tax_return_id/`;
export const USE_GET_NEW_REGIME_URL = `${BASE_API_URL}/incomeTax/computations-new-regime/:income_tax_return_id/`;
export const POST_COMPUTATION_URL = `${BASE_API_URL}/incomeTax/computations/:income_tax_return_id/`;
export const PATCH_COMPUTATION_URL = `${BASE_API_URL}/incomeTax/computations/update/:income_tax_return_id/`;
export const GET_DOWNLOAD_OLD_COMPUTATION_URL = `${BASE_API_URL}/incomeTax/itr-computations-old-pdf/:income_tax_return_id/`;
export const GET_DOWNLOAD_NEW_COMPUTATION_URL = `${BASE_API_URL}/incomeTax/itr-computations-new-pdf/:income_tax_return_id/`;

// ProfileNavTabs
// export const  GET_PROFILE_NAV_TABS=`${BASE_API_URL}/accounts/api/tabs/`
export const GET_PROFILE_NAV_TABS = `${BASE_API_URL}/accounts/api/tabs-list/`;
// TAX PROFILE
export const GET_GOVT_ID_URL = `${BASE_API_URL}/accounts/api/government-ids/`;
export const POST_GOVT_ID_URL = `${BASE_API_URL}/accounts/api/government-ids/`;
export const PATCH_GOVT_ID_URL = `${BASE_API_URL}/accounts/api/government-ids/`;
export const GET_BANK_ACCOUNTS_URL = `${BASE_API_URL}/accounts/api/profile-bank-accounts/`;
export const POST_BANK_ACCOUNT_URL = `${BASE_API_URL}/accounts/api/profile-bank-accounts/`;
export const PATCH_BANK_ACCOUNT_URL = `${BASE_API_URL}/accounts/api/profile-bank-accounts/:bank_id/`;
export const DELETE_BANK_ACCOUNT_URL = `${BASE_API_URL}/accounts/api/profile-bank-accounts/:bank_id/`;
export const PUT_ACCOUNT_BATCH_UPDATE_URL = `${BASE_API_URL}/accounts/api/profile-bank-accounts/`;

export const GOOGLE_REDIRECT_URL = `${BASE_API_URL}/auth/api/login/google/`;
// export const GOOGLE_REDIRECT_URL = `https://stage-api1.beyondtax.co/auth/api/login/google/`;
// export const GOOGLE_REDIRECT_URL = "http://localhost:3000";
// export const GOOGLE_REDIRECT_URL = `http://localhost:3000/auth/api/login/google/`;

// NEW LOGIN SIGNUP
export const POST_NEW_REGISTRAION_API_URL = `${BASE_API_URL}/accounts/api/registration/`;
export const PATCH_BUSINESS_USER_DETAILS_URL = `${BASE_API_URL}/accounts/api/update-business-details/`;
export const POST_NEW_LOGIN_URL = `${BASE_API_URL}/accounts/api/login/`;
export const POST_VERIFY_OTP = `${BASE_API_URL}/accounts/api/verify_otp/`;
export const POST_RESEND_OTP_URL = `${BASE_API_URL}/accounts/api/resend_otp/`;
export const POST_GOOGLE_SIGNUP_URL = `${BASE_API_URL}/accounts/api/google-signup-account-type/`;

// PERMISSIONS
export const GET_STAKEHOLDER_URL = `${BASE_API_URL}/accounts/api/stakeholders/`;
export const GET_STAKEHOLDER_EMAIL_SUGGESTION_URL = `${BASE_API_URL}/accounts/api/email-suggestions/:queryParam`;
export const GET_TABS_URL = `${BASE_API_URL}/accounts/api/tabs/`;
export const DELETE_STAKEHOLDER_URL = `${BASE_API_URL}/accounts/api/stakeholders/delete/:stakeholder_id/`;
export const PATCH_STAKEHOLDER_URL = `${BASE_API_URL}/accounts/api/stakeholders/update/:stakeholder_id/`;
export const POST_STAKEHOLDER_URL = `${BASE_API_URL}/accounts/api/stakeholders/create/`;
// Stakeholder Api's
export const GET_STAKEHOLDER_USER_LIST = `${BASE_API_URL}/accounts/api/Stakeholders-user-list/`;
export const POST_ACCESS_USER_PERMISSION = `${BASE_API_URL}/accounts/api/access/`;

// Liabilities
export const GET_LOAN_TYPES = `${BASE_API_URL}/liabilities/get-liabilities/`;
export const GET_LIABILITIES_OVERVIEW = `${BASE_API_URL}/liabilities/liabilities-overview/`;
// LIABILITIES
export const GET_LIABILITIES_URL = `${BASE_API_URL}/liabilities/get-liabilities/`;
export const POST_FAMILY_LOANS_URL = `${BASE_API_URL}/liabilities/family-loans/:family_loan_id/`;
export const GET_FAMILY_LIABILITIES_OVERVIEW = `${BASE_API_URL}/liabilities/family-loans/:family_loan_id/`;
export const DELETE_INDIVIDUAL_LOAN = `${BASE_API_URL}/liabilities/delete-loans/:family_loan_id/`;

export const GET_BANKING_LOANS_URL = `${BASE_API_URL}/liabilities/banking_loans/:loan_type_id`;
export const POST_BANKING_LOANS_URL = `${BASE_API_URL}/liabilities/banking_loans/:loan_type_id/`;
export const PATCH_BANKING_LOANS_URL = `${BASE_API_URL}/liabilities/banking_loans/:loan_type_id/`;
export const POST_CREDIT_URL = `${BASE_API_URL}/liabilities/credit-card-debts/:loan_type_id/`;
export const PATCH_CREDIT_URL = `${BASE_API_URL}/liabilities/credit-card-debts/:loan_type_id/`;
export const DELETE_LOANS_URL = `${BASE_API_URL}/liabilities/delete-loans/:loan_type_id`;
export const GET_CREDIT_CARD_URL = `${BASE_API_URL}/liabilities/credit-card-debts/:loan_type_id`;

// Assets
export const GET_ASSETS_CATEGORY = `${BASE_API_URL}/assets/get-categeory-assets/:category/`;
export const POST_GOLD_JEWELLARY = `${BASE_API_URL}/assets/gold-and-jewelery/:personalId/`;
export const POST_ART_FURNITURE = `${BASE_API_URL}/assets/art-and-collectibles/:personalId/`;
export const POST_LOAN = `${BASE_API_URL}/assets/loan-to-family-or-friends/:otherId/`;
export const POST_INTELLECTUAL = `${BASE_API_URL}/assets/intellectual-property/:otherId/`;
export const POST_VEHICLE = `${BASE_API_URL}/assets/vehicle-details/:personalId/`;
export const POST_DOMAIN = `${BASE_API_URL}/assets/domain-details/:otherId/`;
// ASSET
export const GET_CATEGORY_ASSET_URL = `${BASE_API_URL}/assets/get-categeory-assets/:category_asset/`;
export const GET_TOTAL_ASSET_COUNT_URL = `${BASE_API_URL}/assets/total-assets-count/`;
export const GET_ASSET_URL = `${BASE_API_URL}/assets/get-assets/`;
export const POST_LAND_DETAILS_URL = `${BASE_API_URL}/assets/land-details/:asset_id/`;
export const GET_LAND_DETAILS_URL = `${BASE_API_URL}/assets/land-details/:asset_id`;
export const PATCH_LAND_DETAILS_URL = `${BASE_API_URL}/assets/land-details/:asset_id/`;
export const GET_BUILDING_DETAILS_URL = `${BASE_API_URL}/assets/building-details/:asset_id`;
export const POST_BUILDING_DETAILS_URL = `${BASE_API_URL}/assets/building-details/:asset_id/`;
export const PATCH_BUILDING_DETAILS_URL = `${BASE_API_URL}/assets/building-details/:asset_id/`;
export const GET_SUKANYA_URL = `${BASE_API_URL}/assets/sukanya-samridhi-yojhana/:asset_id`;
export const POST_SUKANYA_DETAILS_URL = `${BASE_API_URL}/assets/sukanya-samridhi-yojhana/:asset_id/`;
export const PATCH_SUKANYA_DETAILS_URL = `${BASE_API_URL}/assets/sukanya-samridhi-yojhana/:asset_id/`;
export const POST_FURNITURE_DETAILS_URL = `${BASE_API_URL}/assets/furniture-and-fixtures/:asset_id`;
export const PATCH_FURNITURE_DETAILS_URL = `${BASE_API_URL}/assets/furniture-and-fixtures/:asset_id`;
export const GET_GOLD_DETAILS_URL = `${BASE_API_URL}/assets/gold-and-jewelery/:asset_id`;
export const GET_ART_DETAILS_URL = `${BASE_API_URL}/assets/art-and-collectibles/:asset_id`;
export const GET_LOAN_DETAILS_URL = `${BASE_API_URL}/assets/loan-to-family-or-friends/:asset_id`;
export const GET_INTELECTUAL_DETAILS_URL = `${BASE_API_URL}/assets/intellectual-property/:asset_id`;
export const GET_VEHICLE_DETAILS_URL = `${BASE_API_URL}/assets/vehicle-details/:asset_id`;
export const GET_DOMAIN_DETAILS_URL = `${BASE_API_URL}/assets/domain-details/:asset_id`;

export const POST_CAPITAL_DETAILS_URL = `${BASE_API_URL}/assets/capital_expense/:asset_id`;
export const PATCH_CAPITAL_DETAILS_URL = `${BASE_API_URL}/assets/capital_expense/:asset_id`;
export const POST_MANDATE_DETAILS_URL = `${BASE_API_URL}/assets/mandate_details/:asset_id`;
export const PATCH_MANDATE_DETAILS_URL = `${BASE_API_URL}/assets/mandate_details/:asset_id`;
export const GET_REAL_ESTATE_URL = `${BASE_API_URL}/assets/real-estate-overview/REAL ESTATE/`;
export const POST_INTELLECTUAL_COMMERCIAL = `${BASE_API_URL}/assets/commercial-information/:otherId`;
export const GET_ASSET_TYPE_OVERVIEW_URL = `${BASE_API_URL}/assets/assets-overview/:asset_type/`;
export const DELETE_ASSET_URL = `${BASE_API_URL}/assets/delete-assets/:asset_id`;
export const POST_DOMAIN_COMMERCIAL = `${BASE_API_URL}/assets/domain-commercial-info/:otherId`;

export const POST_LETTER_HEAD_DATA = `${BASE_API_URL}/accounts/api/letter-head-pdf/`;

export const GET_CASH_FLOW = `${BASE_API_URL}/moneyManagement/cash-flow/:cashId`;
export const GET_BUDGET = `${BASE_API_URL}/moneyManagement/get-budget/`;
export const POST_GOAL = `${BASE_API_URL}/moneyManagement/goals/`;
export const GET_MANAGER_HOME = `${BASE_API_URL}/moneyManagement/manager/`;

export const GET_DASHBOARD_CONTENT_URL = `${BASE_API_URL}/assets/dashboard/`;

// MONEY MANAGER
export const POST_INITIAL_ACCOUNT_URL = `${BASE_API_URL}/moneyManagement/accounts/`;
export const POST_CATEGORIES_URL = `${BASE_API_URL}/moneyManagement/categories/`;
export const GET_CATEGORY_TRANSACTIONS_URL = `${BASE_API_URL}/moneyManagement/category-transactions/`;
export const PATCH_CATEGORY_URL = `${BASE_API_URL}/moneyManagement/categories/`;
export const DELETE_CATEGORY_URL = `${BASE_API_URL}/moneyManagement/categories/`;
export const GET_CATEGORY_URL = `${BASE_API_URL}/moneyManagement/categories/`;
export const GET_ACCOUNTS_LIST_URL = `${BASE_API_URL}/moneyManagement/list-accounts/`;
export const POST_TRANSACTIONS_URL = `${BASE_API_URL}/moneyManagement/transactions/`;
export const GET_LIST_TRANSACTIONS_URL = `${BASE_API_URL}/moneyManagement/list-transactions/:filter_type`;
export const PATCH_TRANSACTIONS_URL = `${BASE_API_URL}/moneyManagement/transactions/`;
export const GET_MANAGER_HOME_URL = `${BASE_API_URL}/moneyManagement/manager/:managerMonth`;
export const PATCH_ACCOUNT_URL = `${BASE_API_URL}/moneyManagement/accounts/`;
export const GET_MANAGER_CASH_FLOW = `${BASE_API_URL}/moneyManagement/dashboard-cashflow`;
export const POST_EXPENSE_CATEGORY = `${BASE_API_URL}/moneyManagement/add-category-budget/`;
export const POST_SET_CATEGORY = `${BASE_API_URL}/moneyManagement/set/`;
export const DELETE_GOALS_URL = `${BASE_API_URL}/moneyManagement/goals/`;
export const PATCH_GOAL_URL = `${BASE_API_URL}/moneyManagement/goals/`;

// businessForm
export const POST_NEW_BUSINESS_URL = `${BASE_API_URL}/accounts/api/new-business/`;
export const POST_ONGOING_BUSINESS_URL = `${BASE_API_URL}/accounts/api/existing-businesses/`;

// business api's
export const GET_EMPLOYEES_OVERVIEW = `${BASE_API_URL}/employees/employees-overview/`;
export const GET_DETAIL_EMPLOYEE = `${BASE_API_URL}/employees/employees/:employeeId`;
export const GET_EMPLOYEES_WITH_ID = `${BASE_API_URL}/employees/retrieve-employees/`;
export const GET_GENERATE_PAYSLIP = `${BASE_API_URL}/employees/generate-payslip/:payslipId/`;
export const POST_RECORD_LEAVE_URL = `${BASE_API_URL}/employees/manage-leave/`;
export const GET_SENT_EMAIL_URL = `${BASE_API_URL}/employees/payslip-email-sent/:emailId/`;
export const POST_ADD_EMPLOYEE_URL = `${BASE_API_URL}/employees/manage-employee/`;
export const GET_EMPLOYEES_ROLES_URL = `${BASE_API_URL}/employees/roles-list/:business_user_id/`;
export const GET_SEARCH_EMPLOYEE_URL = `${BASE_API_URL}/employees/employee-name-suggestions/:business_user_id/`;
export const GET_BUSINESS_DOCUMENTS_URL = `${BASE_API_URL}/accounts/api/business-documents/:business_user_id/`;
export const DELETE_BUSINESS_DOCUMENTS_URL = `${BASE_API_URL}/accounts/api/business-documents/:business_user_id/:doc_id/`;
export const POST_BUSINESS_DOCUMENTS_URL = `${BASE_API_URL}/accounts/api/business-documents/:business_user_id/`;
export const PATCH_BUSINESS_DOCUMENTS_URL = `${BASE_API_URL}/accounts/api/business-documents/:business_user_id/:doc_id/`;
export const GET_BUSINESS_PROFILE_URL = `${BASE_API_URL}/accounts/api/get-business-profile/`;
export const PATCH_BUSINESS_BASIC_DETAILS_URL = `${BASE_API_URL}/accounts/api/get-business-profile/`;
export const POST_KYC_DATA_URL = `${BASE_API_URL}/accounts/api/business-kyc/`;
export const POST_LEAVE_OVERVIEW = `${BASE_API_URL}/employees/leaves-overview/`;
export const POST_EMPLOYEE_FILTER = `${BASE_API_URL}/employees/employees-filter/`;
export const GET_BUSINESS_DASHBOARD_OVERVIEW_URL = `${BASE_API_URL}/employees/business-dashboard-overview/`;
export const GET_BUSINESS_MEMBERS_URL = `${BASE_API_URL}/accounts/api/members/`;
export const POST_BUSINESS_MEMBERS_URL = `${BASE_API_URL}/accounts/api/members/`;
export const PATCH_BUSINESS_MEMBERS_URL = `${BASE_API_URL}/accounts/api/members/:member_id/`;
export const DELETE_BUSINESS_MEMBERS_URL = `${BASE_API_URL}/accounts/api/members/:member_id/`;
export const POST_BUSINESS_UNREGISTERED_CUSTOMERS_URL = `${BASE_API_URL}/customers/manage-customers/:business_id/`;
// export const GET_BUSINESS_IMPORT_GST_DETAILS_URL = `${BASE_API_URL}/customers/import-gst-details/:business_id/`;
export const GET_BUSINESS_CUSTOMERS_OVERVIEW_URL = `${BASE_API_URL}/customers/manage-customers/:business_id`;
export const GET_BUSINESS_CUSTOMERS_DETAIL_URL = `${BASE_API_URL}/customers/customers/:business_id/:user_id`;
export const PATCH_BUSINESS_CUSTOMERS = `${BASE_API_URL}/customers/manage-customers/:business_id/:user_id/`;
export const DELETE_BUSINESS_CUSTOMER_CONTACT = `${BASE_API_URL}/customers/contact-persons/:business_id/:user_id/:contact_id`;
export const GET_BUSINESS_IMPORT_GST_DETAILS_URL = `${BASE_API_URL}/customers/gst-tax-payer-details/:business_id/`;
export const GET_COMPANY_NAME_SUGGESSTION_URL = `${BASE_API_URL}/customers/customer-name-suggestions/:business_user_id/`;

// settingApi
export const GET_SETTINGS_URL = `${BASE_API_URL}/accounts/api/settings/`;
