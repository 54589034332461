import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import uploadFile from "assets/images/reports/uploadFile.svg";
import usePatchCustomerDetail from "../data/usePatchCustomerDetails";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

const EditCompanyDetail = ({
  triggerElement,
  openCompanyDetails,
  setOpenCompanyDetails,
  detailData,
  setReCallApi,
}) => {
  const [isClose, setIsClose] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState("");
  const businessId = JSON.parse(localStorage.getItem("user")).business_id;

  const [patchData, patchError, patchIsLoading, patchCustomerDetail] =
    usePatchCustomerDetail();

  useEffect(() => {
    if (patchData) {
      showSuccessToast(
        "Successfully updated.",
        "top-right",
        `You have successfully updated ${patchData?.data?.company_name}.`
      );

      setOpenCompanyDetails(false);
      setReCallApi(true);
    }
    if (patchError) {
      showErrorToast("Error in uploading data");
    }
  }, [patchData, patchError]);

  const array = [
    { value: "", label: "Select Industry" },
    { value: "1", label: "Consultancy" },
    { value: "2", label: "Technology" },
    { value: "3", label: "Construction" },
    { value: "4", label: "Clothing" },
    { value: "5", label: "Agriculture" },
    { value: "6", label: "Salaried" },
    { value: "7", label: "Real Estate" },
    { value: "8", label: "Food & Beverage" },
    { value: "9", label: "Consulting" },
    { value: "10", label: "Rental" },
    { value: "11", label: "Sports" },
    { value: "12", label: "Decors" },
    { value: "13", label: "Retail" },
    { value: "14", label: "Healthcare" },
  ];

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files; // Get dropped files
    handleDrop(files); // Call the handleDrop method
  };

  const handleDrop = (files) => {
    setCompanyLogo(files[0]);
  };

  const validateFields = () => {
    const newErrors = {};
    const websiteRegex =
      /^(https?:\/\/)([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    const phoneRegex = /^[0-9]{10,15}$/; // 10 to 15 digits allowed

    // if (!companyLogo) newErrors.companyLogo = "Company logo is required.";

    if (!companyName) {
      newErrors.companyName = "Company Name is required.";
    }
    // else if (!/^[A-Za-z\s]+$/.test(companyName.trim())) {
    //   newErrors.companyName = "Company Name can only contain letters.";
    // }

    // if (!industry) newErrors.industry = "Industry is required.";

    // if (!website) {
    //   newErrors.website = "Website is required.";
    // } else if (!websiteRegex.test(website)) {
    //   newErrors.website =
    //     "Enter a valid website URL starting with http:// or https:// (e.g., https://example.com).";
    // }

    // if (!phone) {
    //   newErrors.phone = "Phone is required.";
    // } else if (!phoneRegex.test(phone)) {
    //   newErrors.phone = "Enter a valid phone number";
    // }

    // if (!address1) newErrors.address1 = "Address1 is required.";
    // if (!address2) newErrors.address2 = "Address2 is required.";

    return newErrors;
  };

  useEffect(() => {
    if (detailData) {
      const industryObj = array.filter((el, i) => {
        return el.value == detailData?.customer?.Industry_type;
      });
      setUserId(detailData?.customer?.id);
      setCompanyName(detailData?.customer?.company_name);
      setIndustry(industryObj[0]?.value ? industryObj[0]?.value : "");
      setWebsite(
        detailData?.customer?.website ? detailData?.customer?.website : ""
      );
      setPhone(detailData?.customer?.phone ? detailData?.customer?.phone : "");
      setEmail(detailData?.customer?.email ? detailData?.customer?.email : "");
      setCompanyLogo(
        detailData?.customer?.company_logo
          ? detailData?.customer?.company_logo
          : ""
      );
      setAddress1(
        detailData?.customer?.address_registered
          ? detailData?.customer?.address_registered
          : ""
      );
      setAddress2(
        detailData?.customer?.address_communication
          ? detailData?.customer?.address_communication
          : ""
      );
    }
  }, [detailData]);

  const handleSubmit = () => {
    setErrors({});
    const res = {
      company_name: companyName,
      Industry_type: industry,
      website: website,
      phone: phone,
      email: email,
      company_logo: companyLogo,
      address_registered: address1,
      address_communication: address2,
    };
    const companyDetailErrors = validateFields();
    if (Object.keys(companyDetailErrors).length > 0) {
      setErrors(companyDetailErrors);
      return;
    }
    const formData = new FormData();
    Object.keys(res).map((el, i) => {
      if (el === "company_logo") {
        if (typeof res[el] === "string" && res[el] !== "") {
          return;
        } else {
          formData.append(el, res[el]);
        }
      } else {
        formData.append(el, res[el]);
      }
    });
    patchCustomerDetail(formData, businessId, userId);
  };
  const resetState = () => {
    if (openCompanyDetails) {
      setOpenCompanyDetails(false);
    }
    setIsClose(false);
  };
  const getContent = () => {
    return (
      <div className="grid grid-cols-12 mb-[0.5rem]">
        <div className="col-span-8">
          <div className="flex">
            <div className="input-container w-[50%]">
              <label className="input-label">Company Name</label>
              <InputField
                className="input-box text-darkGray font-[500]"
                placeholder="Name"
                name="company_name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              {errors.companyName && (
                <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                  {errors.companyName}
                </p>
              )}
            </div>
            <div className="input-container w-[46%]">
              <label className="input-label">Industry</label>
              <div className="group focus-within:border-[#EA580C] flex items-center border-1 border-[#E5E7EB] rounded-md  relative">
                <InputField
                  type="select"
                  className="text-darkGray font-[500] text-[0.9rem] w-[100%] bg-white focus:outline-none py-[0.6rem] px-[0.4rem] rounded-md"
                  options={array}
                  name="industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                />
                {/* <div className="relative border-1 border-black group-focus-within:text-[#EA580C] p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-r-md">
                        <img src={upDown} alt="" />
                       
                      </div> */}
              </div>
              {errors.industry && (
                <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                  {errors.industry}
                </p>
              )}
            </div>
          </div>
          <div className="input-container w-[45%] my-[0.5rem]">
            <label className="input-label">Website</label>
            <div className="group focus-within:border-[#EA580C] flex items-center border-1 border-[#E5E7EB] rounded-md ">
              <span className="group-focus-within:text-[#EA580C] p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                http://
              </span>
              <InputField
                name="website"
                className="text-darkGray font-[500] text-[0.9rem] w-[90%] bg-white focus:outline-none p-[0.4rem] rounded-r"
                placeholder="www.yoursite.com"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            {errors.website && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.website}
              </p>
            )}
          </div>

          <div className="flex ">
            <div className="input-container w-[50%] ">
              <div className="flex justify-between items-center w-[90%]">
                <label className="input-label">Phone</label>
                <label className="text-[0.7rem] text-lightGray">Required</label>
              </div>
              <InputField
                className="input-box text-darkGray font-[500]"
                placeholder="+91 (000) 000-0000"
                type="number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {errors.phone && (
                <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                  {errors.phone}
                </p>
              )}
            </div>

            <div className="input-container w-[50%] ">
              <div className="flex justify-between items-center w-[90%]">
                <label className="input-label">Email</label>
                <label className="text-[0.7rem] text-lightGray">Optional</label>
              </div>
              <InputField
                className="input-box text-darkGray font-[500]"
                placeholder="your@gmail.com"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="input-container my-[0.5rem]">
            <label className="input-label">Address(Registered)</label>
            <InputField
              className="input-box text-darkGray font-[500]"
              placeholder="Address 1"
              name="address1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
            {errors.address1 && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.address1}
              </p>
            )}
          </div>
          <div className="input-container">
            <label className="input-label">
              Address(Communication/Shipping)
            </label>
            <InputField
              className="input-box text-darkGray font-[500]"
              placeholder="Address 2"
              name="address1"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
            {errors.address2 && (
              <p className="text-red-500 text-[0.8rem] font-[500] m-0">
                {errors.address2}
              </p>
            )}
          </div>
        </div>

        {/* photo */}
        <div
          onDrop={(e) => handleFileDrop(e)}
          onDragOver={(e) => e.preventDefault()}
          className="col-span-4 bg-[#F8FAFC] rounded-md flex flex-col justify-center items-center"
        >
          <h4 className="text-[0.9rem] text-darkGray font-[500]">
            Company Logo
          </h4>
          {companyLogo ? (
            <div className="flex flex-col justify-center items-center">
              <img
                src={
                  typeof companyLogo === "string"
                    ? companyLogo
                    : URL.createObjectURL(companyLogo)
                } // Convert file object to a URL
                alt="Company Logo"
                className="w-16 h-16 rounded-full"
              />
              <button
                className="text-[#EA580C] text-[0.9rem] font-[500] mt-[0.5rem]"
                onClick={() => setCompanyLogo("")}
              >
                Change
              </button>
            </div>
          ) : (
            <div
              className="bg-white rounded-md border flex flex-col justify-center items-center py-[2.5rem] px-[0.7rem] cursor-pointer"
              onClick={() => document.querySelector(`#company-logo`).click()}
            >
              <img src={uploadFile} alt="" />
              <h4 className="text-darkGray text-[0.8rem] font-[500]">
                Drop your file here or{" "}
                <span className="text-[#EA580C]">browse</span>
              </h4>
              <h6 className="text-[0.7rem] text-lightGray m-0 font-[400]">
                Maximum size: 2MB
              </h6>
            </div>
          )}
          <InputField
            type="file"
            accept=".jpeg, .png, .jpg"
            id={`company-logo`}
            className="hidden"
            onChange={(e) => setCompanyLogo(e.target.files[0])}
          />
          {errors.companyLogo && (
            <p className="text-red-500 text-[0.8rem] font-[500] m-0">
              {errors.companyLogo}
            </p>
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <DialogBox
        triggerElement={triggerElement}
        heading="Edit Company Details"
        onClose={resetState}
        width="w-[750px]"
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                bg={"bg-[#FB923C]"}
                onClick={handleSubmit}
              />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default EditCompanyDetail;
