import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import warning from "assets/images/BusinessDashboard/BusinessCustomers/deleteWarning.svg";
import React, { useEffect, useState } from "react";
import useDeleteContact from "../data/useDeleteContact";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

const EditPrimaryContactDelete = ({
  triggerElement,
  openProfileEdit,
  setOpenProfileEdit,
  detailData,
  setReCallApi,
  contactId,
}) => {
  const [isClose, setIsClose] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedContact, setSelectedContact] = useState({});
  const businessId = JSON.parse(localStorage.getItem("user")).business_id;
  const [companyName, setCompanyName] = useState("");
  const [deleteData, deleteError, deleteIsLoading, deleteContact] =
    useDeleteContact();
  useEffect(() => {
    if (detailData && contactId) {
      setUserId(detailData?.customer?.id);
      setCompanyName(detailData?.customer?.company_name);
      const editObj = detailData?.customer?.contact_persons?.filter((el, i) => {
        if (el.id === contactId) {
          return true;
        }
      });
      setSelectedContact(editObj?.[0]);
    }
  }, [detailData, contactId]);
  useEffect(() => {
    if (deleteData) {
      showSuccessToast(
        "Successfully deleted.",
        "top-right",
        `You have successfully deleted contact ${selectedContact?.name} from customers`
      );

      setOpenProfileEdit(false);
      setReCallApi(true);
    }
    if (deleteError) {
      showErrorToast("Error in Deleting Contact");
    }
  }, [deleteData, deleteError]);
  const handleSubmit = () => {
    deleteContact(businessId, userId, contactId);
  };
  const resetState = () => {
    if (openProfileEdit) {
      setOpenProfileEdit(false);
    }
  };
  const getContent = () => {
    return (
      <div className="flex flex-col items-center gap-2 mb-2 p-2">
        <div>
          <img src={warning} alt="warning" />
        </div>
        <div>
          <p className="text-[#1F2937] font-[600] m-0 text-center">
            Remove {selectedContact?.name}
          </p>
        </div>
        <div>
          <p className="text-[#6B7280] text-[0.8rem] m-0 text-center">
            Are you sure you would like to remove {selectedContact?.name} from{" "}
            {companyName}?
          </p>
        </div>
      </div>
    );
  };
  return (
    <div>
      <DialogBox
        triggerElement={triggerElement}
        heading=""
        width="max-w-80"
        onClose={resetState}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-center gap-[1rem]">
              <WhiteButton
                onClick={handleSubmit}
                name="Remove"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={handleClose}
                name={"Cancel"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                bg={"bg-[#FB923C]"}
              />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default EditPrimaryContactDelete;
